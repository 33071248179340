/*-----------------------------------
| Overwrite any Bootstrap Styles here
------------------------------------*/


/*-----------------------------------
| Utility
------------------------------------*/

.page-body {
	padding-top: 40px;
}

.pt-140 {
	padding-top: 140px;
}

.mb-20 {
	margin-bottom: 20px;
}

.bookmarked {
	color: gold;
}

/*-----------------------------------
| Fixed Header
------------------------------------*/

.fixedHeader{
	background: #eee;
	position: fixed;
	width: 100%;
	left: 0;
	z-index: 10;
	padding-top: 10px;
	border-bottom: 1px solid #ddd;
}

.spacer{
	height: 105px;
}

.popover, .popover-title{
	/*background-color: #9954BB;*/
}

.popover.right .arrow:after, .popover.left .arrow:after{
	border-right-color: #fff;
	border-left-color: #fff;
}

#notifications ul{
	max-height: 300px;
	overflow-y: scroll;
}

/*-----------------------------------
| Job List Additional Styles
------------------------------------*/

.job-search {
	display: inline-block;
	margin-top: 8px;	
}

/*-----------------------------------
| View Job Additional Styles
------------------------------------*/

.section-heading {
	margin-bottom: 25px;
	border-bottom: 2px solid #ccc;
	padding-bottom: 10px;
	padding-top: 20px;
}

.notes{
	list-style: none;
	margin: 0 0 35px;
	padding: 0;
	border-bottom: 1px solid #E5E5E5;
}

.notes li{
	padding: 15px 0 0;
}

.notes span{
	background: #F5F5F5;
	display: block;
	font-weight: bold;
	padding: 10px;
	margin: 15px 0 0;
}

/*-----------------------------------
| Job Report Additional Styles
------------------------------------*/

.print-section-heading {
	margin-bottom: 15px;
	border-bottom: 2px solid #ccc;
	padding-bottom: 10px;
	padding-top: 10px;
	margin-top: 0;
}

p.text-block {
	display: block;
    margin-top: 6px;
    margin-bottom: 10px;
}

.no-space {
	margin-bottom: 0;
}

/*-----------------------------------
| 
------------------------------------*/

.container.login{
	padding: 200px 0;
}

.table tr:hover td {
	background: #eee !important;
	cursor: pointer;
}

.btn-delete{
	margin: 0 10px 0 0;
}

#calendar{
	margin: 0 0 50px;
}

.pending-hol{
	margin: 0 0 80px;
}

.wall{
	height: 200px;
	overflow-y: scroll;
}

.messages{
	padding: 0 !important;
	margin: 0 !important;
}

.messages li{
	list-style: none;
	border-bottom: 1px solid #ccc;
	padding: 0 0 10px;
	margin: 0 0 10px;
}

.byline{
	display: block;
	color: #999;
	font-size: 0.8em;
	margin: 5px 0 0;
}

/*-----------------------------------
| Task List
------------------------------------*/

.taskList{
	margin: 0 0 25px;
}

.taskList li{
	border-bottom: 1px solid #eee;
	padding: 15px 15px 10px;
}

.taskList li:nth-child(odd){
	background: #eee;
}

.taskList label{
	float: left;
}

.taskList p{
	font-size: 0.9em;
	margin: 10px 0 0;
	padding: 0 0 0 28px;
}

.taskList input{
	float: left;
	margin: 3px 15px 0 0;
}

.key{
	margin: 0 0 30px;
}

.key li{
	display: inline-block;
	padding: 0 10px 5px;
}

.key li span{
	display: block;
	width: 15px;
	height: 15px;
	float: left;
	margin: 0 5px 0 0;
	border-radius: 50%;
}

.chris {
	background: #72a518; /* GREEN */
	border: 1px solid #ccc;
}

.matt {
	background: #429bec; /* BLUE */
	border: 1px solid #ccc;
}

.paul{
	background: #AF9BB8;
	border: 1px solid #8D719B;
}

.steve{
	/*background: #84C2E6;
	border: 1px solid #50A8DC;*/
	background: #8BBCD7;
	border: 1px solid #5AA0C7;
}

.pete {
	background: #C8CFEE;
	border: 1px solid #B1BBE7;
}

.darran {
	background: #F1B480;
	border: 1px solid #EB944B;
}

.tom {
	background: #ff0000; /* RED */
	border: 1px solid #ccc;
}

.leigh {
	background: #ac70f9; /* GREEN */
	border: 1px solid #ccc;
}

.freelance {
	/* background: #ffca2c; */ /* YELLOW */
	background: #791205;
	border: 1px solid #ccc;
}

/*-----------------------------------
| Invoice System
------------------------------------*/

.invoiceList{
	margin: 0 0 25px;
}

.invoiceList li{
	list-style: none;
	padding: 15px;
}

.invoiceList li:nth-child(odd){
	background: #eee;
}

.totals{
	float: right;
	font-size: 1.2em;
}

.totals li{
	list-style: none;
	text-align: right;
	margin: 0 0 5px;
}

.relative{
	position: relative;
}

.duedate{
	position: absolute;
	top: 30px;
	right: 0;
	font-size: 1.4em;
}

.noteList{
	margin: 15px 0;
}

.noteList li{
	list-style: none;
	border-bottom: 1px solid #eee;
	padding: 15px 0;
}

.noteList p{
	margin: 20px 0 0;
}



/*-----------------------------------
| Notifications
------------------------------------*/

.lb{
	background: #d9eeff;
	border-top: 1px solid #cee2f2;
	border-bottom: 1px solid #cee2f2;
}

#notifications li{
	position: relative;
}

#notifications a{
	padding-right: 50px;
}

.clearBtn{
	background: none;
	position: absolute;
	right: 10px;
	top: 0;
	border: none;
	color: #ccc;
	font-size: 1.1em;
	outline: none;
}

/*-----------------------------------
| Client List
------------------------------------*/

.clientList, .clientList li{
	list-style: none;
	padding: 0;
	margin: 0;
}

.clientList{
	margin-bottom: 50px;
}

.clientList a{
	border-bottom: 1px solid #eee;
	display: block;
	padding: 10px;
}

.clientList li:nth-child(even) a{
	background: #eee;
}

/*-----------------------------------
| Holiday
------------------------------------*/

.fullday{
	background: #53903B;
}

.halfday{
	background: #3A87AD;
}

.sick{
	background: #FFA956;
}

.sickhalf{
	background: #FFE088;
}

.medical{
	background: #C674EF;
}

.medicalhalf{
	background: #6B2737;
}

.compassionate{
	background: #C2818F;
}